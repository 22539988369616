import { fillTranslationTextWithVariables } from '../../hooks';
import {
  GameFlow,
  GetReady,
  Instructions,
  Intro,
  PlayGame,
} from '../../components/game';
import Game from './CPTGame';
import { useCPTGameActions, useCPTGameData } from './state';
import { CPTGameResultForSubmission } from './state/CPTState';
import { tutorialImagesA, tutorialImagesB } from './tutorialImages';
import Feedback from '../../components/game/Feedback';
import { useTranslation } from 'appState';

const CPTGameFlow:React.FC = () => {
  const lang = useTranslation('CPT');
  const { startIntroTimer, endIntroTimer } = useCPTGameActions();
  const { gameResult } = useCPTGameData();

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            description={lang.intro.description}
            buttonLabel={lang.intro.btnText}
            superPowerName={lang.intro.superPowerName}
            superPower={lang.intro.superPower}
            gameTitle={lang.gameName}
          />
        ),
        () => (
          <Instructions
            name={`${lang.gameName}  ${lang.gameTitleExtraFirst}`}
            instructions={lang.instructions.textFirst}
            images={tutorialImagesA}
            texts={lang.tutorialA}
            tutorial
            onStart={() => {
              startIntroTimer();
            }}
            onEnd={() => {
              endIntroTimer(false);
            }}
          />
        ),
        () => <GetReady />,
        () => <Game practiceMode />,
        () => (
          <PlayGame
            gameName={`${lang.gameName}  ${lang.gameTitleExtraFirst}`}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => <Game />,
        () => (
          <Instructions
            name={`${lang.gameName}  ${lang.gameTitleExtraSecond}`}
            instructions={lang.instructions.textSecond}
            images={tutorialImagesB}
            texts={lang.tutorialB}
            tutorial
            onStart={() => {
              startIntroTimer();
            }}
            onEnd={() => {
              endIntroTimer(true);
            }}
          />
        ),
        () => <GetReady />,
        () => <Game practiceMode extendedMode />,
        () => (
          <PlayGame
            gameName={`${lang.gameName}  ${lang.gameTitleExtraSecond}`}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => <Game extendedMode />,
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score: gameResult.cptMetadata.percentCorrect },
              )
            }
            description={lang.feedback.description}
          />
        ),
      ]}
    </GameFlow>
  );
};

export default CPTGameFlow;
export type Results = CPTGameResultForSubmission;
