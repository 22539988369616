import envLocal from './environment.local';
import envDev from './environment.dev';
import envProd from './environment.prod';

const envType = ['local', 'dev', 'prod'] as const;
const currentEnv = process.env.REACT_APP_ENV as typeof envType[number];

if (!envType.includes(currentEnv)) {
  throw new Error(`Invalid REACT_APP_ENV value; expected one of: ${envType.join(', ')}`);
}

const env = {
  'local': envLocal,
  'dev': envDev,
  'prod': envProd,
}[currentEnv];

/**
 * Flags the "insider" mode which enables some features we don't want
 * to expose to end users, such as:
 * * routing to a particular game
 * * routing to a sample assessment report
 */
export const INSIDER_BUILD = env.insiderBuild;

export const ALTERNATIVE_GAME_FLOW = env.alternativeGameFlow;

export const API_URL = env.apiUrl;

export const ASPECT_RATIO_WIDTH = 16;
export const ASPECT_RATIO_HEIGHT = 9;
