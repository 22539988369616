import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  GameFlow,
  GetReady,
  Instructions,
  Intro,
  PlayGame,
} from 'components/game';
import { useAppStateActions, useTranslation } from 'appState';
import { fillTranslationTextWithVariables } from 'hooks';
import { getDeviceInfo } from 'utils';
import { GameId } from 'types/enums';

import Game from './Game';
import tutorialImages from './tutorialImages';
import { GameStage, MTPTResults, MTPTStageResult } from './types';
import Feedback from '../../components/game/Feedback';

const MTPT: React.FC = () => {
  const lang = useTranslation('MTPT');
  const { gameFlowForward, submitGameResults } = useAppStateActions();

  const [introStart, setIntroStart] = useState(-1);
  const [introEnd, setIntroEnd] = useState(-1);
  const onIntroStart = useCallback(
    () => {
      setIntroStart(Date.now())
    },
    [setIntroStart],
  );
  const onIntroEnd = useCallback(
    () => {
      setIntroEnd(Date.now())
    },
    [setIntroEnd],
  );

  const [stageResults, setStageResults] = useState<MTPTStageResult[]>([]);

  const onFinish = useCallback((stageResults: MTPTStageResult) => {
    setStageResults(results => results.concat(stageResults));
    gameFlowForward();
  }, [gameFlowForward, setStageResults]);

  useEffect(() => {
    if (stageResults.length === 3) {
      submitGameResults(GameId.MTPT, {
        mtptScores: stageResults,
        mtptMetadata: {
          timeSpentOnIntroPage: introEnd - introStart,
          device: getDeviceInfo(),
        },
      });
    }
  }, [stageResults, submitGameResults, introStart, introEnd]);

  const score = useMemo(() => {
    let max = 0;
    stageResults.filter(f => !f.isPractice).forEach(m => {
      if (m.maxCompletionPercent > max) {
        max = m.maxCompletionPercent;
      }
    });
    return max * 100;
  }, [stageResults]);

  return (
    <GameFlow>
      {[
        () => (
          <Intro
            gameTitle={lang.gameTitle}
            description={lang.description}
            superPowerName={lang.superPowerName}
            superPower={lang.superPower}
            buttonLabel={lang.introButtonLabel}
          />
        ),
        () => (
          <Instructions
            name={lang.gameTitle}
            instructions={lang.instructions}
            onStart={onIntroStart}
            onEnd={onIntroEnd}
            tutorial
            images={tutorialImages}
            texts={lang.tutorial}
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            stage={GameStage.trialRunB}
            feedbackHeader={lang.stageFeedback[0].header}
            feedbackText={lang.stageFeedback[0].text}
            totalTime={60_000}
            onFinish={onFinish}
            quittable
            showTimer
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            stage={GameStage.trialRunA}
            feedbackHeader={lang.stageFeedback[1].header}
            feedbackText={lang.stageFeedback[1].text}
            totalTime={60_000}
            onFinish={onFinish}
            quittable
            showTimer
          />
        ),
        () => (
          <PlayGame
            gameName={lang.gameTitle}
            btnText={lang.playGame.btnText}
            text={lang.playGame.text}
          />
        ),
        () => <GetReady />,
        () => (
          <Game
            stage={GameStage.mainRun}
            feedbackHeader={lang.stageFeedback[2].header}
            feedbackText={lang.stageFeedback[2].text}
            totalTime={60_000 * 5}
            onFinish={onFinish}
            quittable
            showTimer
          />
        ),
        () => (
          <Feedback
            title={lang.feedback.title}
            subTitle={
              fillTranslationTextWithVariables(
                lang.feedback.subTitle,
                { score },
              )
            }
          />
        ),
      ]}
    </GameFlow>
  );
};

export default MTPT;
export type Results = MTPTResults;
